import { Box } from '@mui/material';

import PageMargins from '@/components/ui/PageMargins/PageMargins';

import MainInfo from './MainInfo/MainInfo';
import ProductData from './ProductData/ProductData';
import { productDetailsDataPropTypes } from './propTypes';
import RequestForm from './RequestForm/RequestForm';

const ProductDetailsPage = ({ data }) => {
  return (
    <PageMargins>
      <Box mb={8.5} mt={6}>
        <MainInfo
          name={data?.name}
          familyName={data?.family_name}
          price={data?.rental_price}
          priceBasis={data?.rental_price_basis}
          images={data?.images}
          form={<RequestForm data={data} />}
          initialPrice={data?.premium_product_original_price}
        />
      </Box>
      <Box mb={12.5}>
        <ProductData
          attributes={data?.attributes}
          datasheets={data?.datasheets}
          productSample={data?.product_sample}
          description={data?.product_description}
        />
      </Box>
    </PageMargins>
  );
};

ProductDetailsPage.propTypes = {
  data: productDetailsDataPropTypes,
};

export default ProductDetailsPage;
