import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  Paper,
  Stack,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import Select from '@/components/ui/Select/Select';
import getConfig from '@/configs/global';
import { downloadFile } from '@/helpers/downloadFile';
import useFormatMessage from '@/hooks/useFormatMessage';
import { useMobileMediaQuery } from '@/hooks/useMobileMediaQuery';

import { attributesPropTypes, datasheetsPropTypes } from '../propTypes';
import {
  Chip,
  DataTableCell,
  DownloadButton,
  Table,
  TableCell,
} from './ProductData.style';

const {
  publicRuntimeConfig: { FEAT_FLAG_PRODUCT_DETAILS_DATASHEETS },
} = getConfig();

const TABS_KEYS = {
  TECHNICAL_DATA: 'technical-data',
  DESCRIPTION: 'description',
};

const sortAlphabetically = (a, b) => {
  const valueA = a?.key?.toLowerCase();
  const valueB = b?.key?.toLowerCase();

  if (valueA < valueB || typeof valueB !== 'string') {
    return -1;
  }
  if (valueA > valueB || typeof valueA !== 'string') {
    return 1;
  }
  return 0;
};

const ProductData = ({
  attributes,
  datasheets,
  productSample,
  description,
}) => {
  const formatMessage = useFormatMessage();
  const [activeTab, setActiveTab] = useState(null);
  const mobileMediaQuery = useMobileMediaQuery();
  const { formatNumber } = useIntl();

  const tabs = useMemo(
    () => [
      {
        key: TABS_KEYS.TECHNICAL_DATA,
        label: formatMessage('product_details_page_technical_data_chip'),
        visible: attributes?.length > 0,
      },
      {
        key: TABS_KEYS.DESCRIPTION,
        label: formatMessage('product_details_page_description_chip'),
        visible: typeof description === 'string' && Boolean(description),
      },
    ],
    [attributes, description]
  );

  const visibleTabs = useMemo(() => tabs.filter(({ visible }) => visible), [
    tabs,
  ]);

  useEffect(() => {
    setActiveTab(visibleTabs[0]?.key || null);
  }, [visibleTabs]);

  const downloadDatasheet = () => {
    if (datasheets?.length > 0) {
      const datasheet = datasheets[0];
      downloadFile(datasheet.url, datasheet.name);
    }
  };

  const handleTabChange = key => {
    if (key !== activeTab) {
      setActiveTab(key);
    }
  };

  const formatValue = value => {
    if (typeof value !== 'number') {
      return value;
    }
    return formatNumber(value);
  };

  const renderActiveTabTableData = () => {
    if (activeTab === TABS_KEYS.TECHNICAL_DATA && attributes?.length > 0) {
      return attributes
        .slice()
        .sort(sortAlphabetically)
        .map(({ key, value, unit }, index) => (
          <TableRow key={index}>
            <DataTableCell>{key}</DataTableCell>
            <DataTableCell sx={{ textAlign: 'right' }}>
              {`${formatValue(value)}${unit ? `\u202F${unit}` : ''}`}
            </DataTableCell>
          </TableRow>
        ));
    }

    if (
      activeTab === TABS_KEYS.DESCRIPTION &&
      typeof description === 'string' &&
      Boolean(description)
    ) {
      return (
        <TableRow>
          <TableCell colSpan={2}>
            <Typography
              variant="body1Redesign"
              component="p"
              color="text.secondary"
              sx={{ whiteSpace: 'pre-line' }}>
              {description}
            </Typography>
          </TableCell>
        </TableRow>
      );
    }
  };

  return (
    <Box maxWidth={747} mx="auto">
      {visibleTabs.length > 0 &&
        activeTab &&
        (mobileMediaQuery ? (
          <Select
            options={tabs
              .filter(({ visible }) => visible)
              .map(({ key, label }) => ({
                label: label.toUpperCase(),
                value: key,
              }))}
            onChange={event => handleTabChange(event.target.value)}
            value={activeTab}
            variant="outlined"
            size="medium"
          />
        ) : (
          <Stack spacing={1} direction="row">
            {visibleTabs.map(({ key, label }) => (
              <Chip
                key={key}
                label={label}
                variant={activeTab === key ? 'filled' : 'outlined'}
                color="primary"
                clickable
                onClick={() => handleTabChange(key)}
              />
            ))}
          </Stack>
        ))}
      {activeTab && (
        <>
          <TableContainer component={Paper} elevation={0} sx={{ mt: 4, mb: 5 }}>
            <Table hasProductSample={Boolean(productSample)}>
              <TableBody>
                {productSample && (
                  <TableRow>
                    <TableCell colSpan={2}>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <InfoIcon color="primary" fontSize="small" />
                        <Typography
                          color="primary"
                          variant="body1Redesign"
                          component="div"
                          sx={{
                            fontWeight: theme =>
                              theme.typography.fontWeightSemiBold,
                          }}>
                          {formatMessage(
                            'product_details_page_technical_data_sample_name',
                            { name: productSample }
                          )}
                        </Typography>
                      </Stack>
                    </TableCell>
                  </TableRow>
                )}
                {renderActiveTabTableData()}
              </TableBody>
            </Table>
          </TableContainer>
          {FEAT_FLAG_PRODUCT_DETAILS_DATASHEETS &&
            datasheets?.length > 0 &&
            activeTab === TABS_KEYS.TECHNICAL_DATA && (
              <Stack alignItems="center" mb={8}>
                <DownloadButton
                  color="primary"
                  size="extraLarge"
                  fullWidth
                  sx={{ maxWidth: 323 }}
                  startIcon={<ArrowDownwardIcon fontSize="small" />}
                  onClick={downloadDatasheet}>
                  {formatMessage(
                    'product_details_page_download_datasheet_button'
                  )}
                </DownloadButton>
              </Stack>
            )}
        </>
      )}
      <Box mt={8} mb={10}>
        <Typography
          color="text.secondary"
          variant="captionRedesign"
          component="p">
          {formatMessage('product_details_page_asterisk_text')}
        </Typography>
      </Box>
    </Box>
  );
};

ProductData.propTypes = {
  attributes: attributesPropTypes,
  datasheets: datasheetsPropTypes,
  productSample: PropTypes.string,
  description: PropTypes.string,
};

export default ProductData;
