import { InputAdornment } from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers';
import {
  differenceInCalendarDays,
  format,
  isBefore,
  isPast,
  isSameWeek,
  isSunday,
  isThisISOWeek,
  isToday,
  startOfWeek,
} from 'date-fns';
import de from 'date-fns/locale/de';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import Input from '@/components/ui/Input/Input';
import SvgIcon from '@/components/ui/SvgIcon/SvgIcon';
import scrollIntoViewIfNeeded from '@/helpers/scrollIntoViewIfNeeded';
import { formatDate } from '@/helpers/utils';

import ClickAwayListener from '../ClickAwayListener/ClickAwayListener';
import {
  CalendarWrapper,
  CustomPickersDay,
  Wrapper,
} from './InputCalendar.style';

/**
 * User control for selecting a day or a week.
 */
const InputCalendar = ({
  id,
  label,
  value,
  name,
  onChange,
  onBlur,
  tabIndex,
  inputRef,
  error,
  helperText,
  required,
  disabled,
  disabledTo,
  fullWidth,
  presented,
  isWeekCalendar,
  displayWeekNumber,
}) => {
  const [unfolded, setUnfolded] = useState(false);
  const [hoveredDay, setHoveredDay] = useState(null);

  const calendarRef = useRef(null);
  const innerInputRef = useRef(null);

  const inputValue = value
    ? isWeekCalendar
      ? format(value, "'KW'II' 'RRRR")
      : formatDate(value)
    : '';

  const handleOpenCalendar = () => setUnfolded(true);

  const handleIconClick = () => {
    if (!disabled) {
      innerInputRef.current.focus();
    }
  };

  const getInputIconColor = () => {
    if (error) {
      return 'error';
    }
    if (disabled) {
      return 'disabled';
    }
    return 'primary';
  };

  const handleCalendarChange = date => {
    setUnfolded(false);
    calendarRef.current.focus();
    onChange(date);
  };

  useEffect(() => {
    if (unfolded) {
      const el = calendarRef?.current;
      scrollIntoViewIfNeeded(el);
    }
  }, [unfolded]);

  return (
    <ClickAwayListener onClickAway={() => setUnfolded(false)}>
      <Wrapper fullWidth={fullWidth}>
        <Input
          id={id}
          readOnly
          disabled={disabled}
          variant="outlined"
          size="medium"
          focused={false}
          fullWidth
          inputRef={e => {
            innerInputRef.current = e;

            if (inputRef) {
              if (typeof inputRef === 'function') {
                inputRef(e);
              } else {
                inputRef.current = e;
              }
            }
          }}
          onBlur={onBlur}
          label={label}
          value={inputValue}
          error={error}
          required={required}
          name={name}
          onFocus={handleOpenCalendar}
          helperText={helperText || ' '}
          {...(tabIndex !== null && {
            tabIndex: tabIndex,
          })}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SvgIcon
                  name="calendar"
                  color={getInputIconColor()}
                  onClick={handleIconClick}
                  sx={{
                    cursor: disabled ? 'initial' : 'pointer',
                  }}
                />
              </InputAdornment>
            ),
          }}
        />
        {unfolded ? (
          <CalendarWrapper ref={calendarRef}>
            <DateCalendar
              data-cy="date-calendar"
              value={value}
              reduceAnimations
              onChange={handleCalendarChange}
              disablePast
              disableHighlightToday
              sx={{
                '& .MuiDayCalendar-weekContainer': {
                  margin: 0,
                },
                '& .MuiDayCalendar-weekNumber, .MuiDayCalendar-weekNumberLabel': {
                  borderRight: '1px solid black',
                },
              }}
              referenceDate={presented}
              displayWeekNumber={displayWeekNumber}
              showDaysOutsideCurrentMonth
              shouldDisableDate={date =>
                isToday(date) || isSunday(date) || isBefore(date, disabledTo)
              }
              slots={{ day: isWeekCalendar && Day }}
              slotProps={{
                day: ownerState => {
                  if (isWeekCalendar)
                    return {
                      'data-cy': `calendar: day ${format(
                        ownerState.day,
                        'dd'
                      )}`,
                      selectedDay: value,
                      hoveredDay,
                      onPointerEnter: () => setHoveredDay(ownerState.day),
                      onPointerLeave: () => setHoveredDay(null),
                    };
                  return {
                    'data-cy': `calendar: day ${format(ownerState.day, 'dd')}`,
                  };
                },
                calendarHeader: {
                  'data-cy': 'calendarDate',
                },
                nextIconButton: {
                  'data-cy': `calendar: next month`,
                },
                previousIconButton: {
                  'data-cy': `calendar: prev month`,
                },
              }}
            />
          </CalendarWrapper>
        ) : null}
      </Wrapper>
    </ClickAwayListener>
  );
};

const Day = ({ day, selectedDay, hoveredDay, ...other }) => {
  const isPastWeek = isPast(day) && !isThisISOWeek(day);
  return (
    <CustomPickersDay
      {...other}
      day={day}
      sx={{ px: 2.5 }}
      disabled={isPastWeek}
      disableMargin
      selected={false}
      daysSinceStartOfWeek={differenceInCalendarDays(
        day,
        startOfWeek(day, { locale: de })
      )}
      isSelected={isSameWeek(day, selectedDay, { locale: de })}
      isHovered={isSameWeek(day, hoveredDay, { locale: de })}
    />
  );
};

InputCalendar.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  /** Displayed value. Triggers controlled mode. */
  value: PropTypes.instanceOf(Date),
  /** Displayed name of input. */
  name: PropTypes.string,
  /** Event listener. Called with Date in parameter. */
  onChange: PropTypes.func,
  /** OnBlur event listener. */
  onBlur: PropTypes.func,
  /** First presented month when calendar is revealed. */
  presented: PropTypes.instanceOf(Date),
  /** Tab index passed to input. */
  tabIndex: PropTypes.number,
  /** Ref to raw html element. */
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  /** Show error Input state */
  error: PropTypes.bool,
  /** Show helper text for Input */
  helperText: PropTypes.string,
  /** Show required styles for Input*/
  required: PropTypes.bool,
  /** Disable Input */
  disabled: PropTypes.bool,
  /** Disable range of dates. */
  disabledTo: PropTypes.instanceOf(Date),
  /** Show full width Input */
  fullWidth: PropTypes.bool,
  /** Display week number in calendar */
  displayWeekNumber: PropTypes.bool,
  /** Flag to switch between the day or week calendar */
  isWeekCalendar: PropTypes.bool,
};

export default InputCalendar;
